const toggleQuestion = (number) => {
  const content = document.querySelector(`#content-q${number}`);
  if (!content) {
    console.error("Question not found!");
    return;
  }
  if (content.classList.contains("collapsed")) {
    content.classList.remove("collapsed");
  } else {
    content.classList.add("collapsed");
  }

  const trigger = document.querySelector(`#toggle-q${number}`);
  if (trigger.classList.contains("collapsed")) {
    trigger.classList.remove("collapsed");
  } else {
    trigger.classList.add("collapsed");
  }
};

[1, 2, 3, 4, 5].forEach((item) => {
  document
    .querySelector(`#toggle-q${item}`)
    .addEventListener("click", () => toggleQuestion(item));
});
